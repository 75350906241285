import React, { useState, useEffect } from 'react'
import { Button, Select, Grid, Dimmer, Loader, Modal, Table, Input, Message, Icon, ButtonContent } from 'semantic-ui-react'
import { getMetadataLists, getAccountLists, createTrustedVendor, getMetadataDetailsFromKey } from '../service'
import { commercialCloud } from '../../../../../Config/appConstant'
import config from '../../../../../Config/Config'
import axios from 'axios'

export default function GlobalUpdateTrustedVendor(props) {
  const [buAccounts, setBuAccounts] = useState({ isLoading: false, error: null, data: null })
  const [vendorNameFromOnChange, setVendorNameFromOnChange] = useState("")
  const [metadata, setMetadata] = useState({
    isLoading: false, error: null, data: null, sortKey: 0, listOfTrustedVendor: []
  })
  const [selectedModalData, setSelectedModalData] = useState({ modalTitle: "", data: null, index: -1 })
  const [openModal, setOpenModal] = useState(false)
  const [vendorIds, setVendorIds] = useState([{ id: 0, value: '', error: false, message: "" }])
  const [searchedResult, setSearchedResult] = useState({
    data: [], isLoading: false, message: "",
    openMessage: false, error: ""
  })
  const [whiteListTrustedVendor, setWhiteListTrustedVendor] = useState({
    data: [],
    isLoading: false,
    message: "",
    error: ""
  })
  const [blackListTrustedVendor, setBlackListTrustedVendor] = useState({
    data: [],
    isLoading: false,
    message: "",
    error: ""
  })
  const [accountDetail, setAccountDetail] = useState({
    data: [],
    isLoading: false,
    message: "",
    error: ""
  })
  const fetchAccountLists = (token) => {
    setBuAccounts({ isLoading: true, data: null, error: null })
    getAccountLists(token, props?.selectCloud).then((item) => {
      if (selectedModalData.data !== null && selectedModalData.modalTitle !== '') {
        setBuAccounts({ isLoading: false, data: item.data?.body?.data?.filter((item) => !selectedModalData.data?.Associations?.includes(item?.value)), error: null })
      }
      else {
        setBuAccounts({ isLoading: false, data: item.data?.body?.data, error: null })
      }
    }).catch(error => {
      setBuAccounts({ isLoading: false, data: null, error: error.message })
      console.error(error)
    })
  }
  const fetchMetadataLists = (token) => {
    setMetadata((preData) => ({ ...preData, isLoading: true }))
    getMetadataLists(token, "GlobalTrustedVendors", props?.selectCloud).then((response) => {
      if (response?.data?.data.length > 0) {
        let listOfTrustedVendor = []
        let trustedVendorUniqueName = new Set()
        let sortKeys = []
        response?.data?.data?.map((item, index) => {
          trustedVendorUniqueName.add(item?.GroupKey)
          sortKeys.push(parseInt(item.SortKey))
        })

        trustedVendorUniqueName.forEach((item) => {
          listOfTrustedVendor.push({
            key: item,
            text: item,
            value: item
          })
        })
        sortKeys.sort((a, b) => b - a)
        setMetadata((preData) => ({
          ...preData, isLoading: false,
          data: response?.data?.data,
          listOfTrustedVendor: listOfTrustedVendor,
          sortKey: sortKeys[0] + 1
        }))
      }

    }).catch(error => {
      setMetadata((preData) => ({
        ...preData,
        isLoading: false,
        data: null,
        error: error.message,
        listOfTrustedVendor: [],
        sortKey: 0
      }))
      console.log(error)
    })
  }
  const fetchMetadataDetailsFromKey = (token) => {
    getMetadataDetailsFromKey(token, "BlacklistedTrustedVendors", props?.selectCloud).then((response) => {
      const result = response?.data?.body?.data
      if (result?.length > 0) {

        setBlackListTrustedVendor({ data: result[0]?.Metadata?.BlacklistedTrustedVendors?.TrustedVendors[0]?.MetadataValue })
      }

      getMetadataDetailsFromKey(token, "WhitelistedTrustedVendors", props?.selectCloud).then((response) => {
        const reponseData = response?.data?.body?.data
        setWhiteListTrustedVendor({ data: reponseData[0]?.Metadata?.WhitelistedTrustedVendors?.TrustedVendors[0]?.MetadataValue })
      }).catch(err => {
        console.error(err)
      })
    }).catch(err => {
      console.error(err)
    })
  }
  useEffect(() => {
    let token = props.authState.accessToken.accessToken
    if (token) {
      fetchMetadataLists(token)
      fetchAccountLists(token)
      fetchMetadataDetailsFromKey(token)
    }
  }, [props?.selectCloud, props?.vendorType])

  const handleCloseModal = () => {
    setOpenModal(false)
    setVendorIds([{ id: 0, value: '', error: false, message: "" }])
  }
  const handleOpenModal = (data) => {
    setOpenModal(true)
    setSelectedModalData((prevData) => ({
      ...prevData,
      modalTitle: vendorNameFromOnChange,
      data: { GroupKey: vendorNameFromOnChange }
    }))
  }
  const handleUpdateTrustedVendor = () => {

    let token = props.authState.accessToken.accessToken

    let payload = vendorIds.map((item, index) => {
      return ({
        MetadataKey: "GlobalTrustedVendors",
        SortKey: metadata?.sortKey,
        GroupKey: vendorNameFromOnChange,
        LastModifiedBy: props?.authState?.accessToken?.claims?.sub,
        MetadataType: "Portal",
        MetadataValue: item.value,
        Associations: [],
        RuleList: []
      })
    })

    setSearchedResult({
      data: [], isLoading: true, message: "",
      openMessage: false, error: ""
    })

    createTrustedVendor(token, payload, props?.selectCloud).then((res) => {
      if (res.status === 201) {
        setSearchedResult({
          data: [], isLoading: false, message: "Record Updated successfully!",
          openMessage: true, error: ""
        })
        handleCloseModal()
        fetchMetadataLists(token)
        setInterval(() => { handleRemoveMessageBox() }, 5000)
      } else {
        setSearchedResult({
          data: [], isLoading: false, message: "",
          openMessage: true, error: "Failed to update record!"
        })
        handleCloseModal()
        setInterval(() => { handleRemoveMessageBox() }, 5000)
      }
    }).catch(error => {
      console.error(error)
      handleCloseModal()
      setSearchedResult({
        data: [], isLoading: false, message: "",
        openMessage: true, error: error.message
      })
      setInterval(() => { handleRemoveMessageBox() }, 5000)
    })
  }

  const handleRemoveMessageBox = () => {
    setSearchedResult((prevState) => ({ ...prevState, openMessage: false, error: "", message: "" }))
  }

  return (
    <React.Fragment>
      <div style={{ width: "100%", top: 0, position: "absolute", left: 0 }}>
        <div style={{ marginTop: "20px", width: "100%", display: "flex", justifyContent: "center" }} >
          {searchedResult.openMessage ? <Message size="large" style={{ width: "40%" }} onDismiss={handleRemoveMessageBox}
            content={searchedResult.message === "" ? searchedResult.error : searchedResult.message}
            color={searchedResult.message === "" ? "red" : "green"} /> : null}
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        size='small'
      >
        <Modal.Header>{selectedModalData?.data?.GroupKey}</Modal.Header>
        <Modal.Content style={{ minHeight: "100px" }}>
          <Dimmer active={searchedResult.isLoading || accountDetail?.isLoading}>
            <Loader />
          </Dimmer>
          <div >
            <label style={{ fontWeight: "bold", fontSize: "16px" }}>Enter New Vendor Account or AMI source pattern:</label>
          </div>
          <div style={{ marginTop: "10px", width: "100%" }}>
            <Input
              name="vendor-id"
              value={vendorIds[0].value}
              onChange={(e, data) => {
                if (!isNaN(data?.value)) {
                  if (data?.value.length <= 12) {
                    const newInputFields = [...vendorIds]
                    newInputFields[0].value = data?.value
                    newInputFields[0].error = false
                    newInputFields[0].message = ""

                    let token = props.authState.accessToken.accessToken
                    if (data.value === "") {
                      newInputFields[0].error = false
                      newInputFields[0].message = ""
                    }
                    else if (data?.value?.length < 12) {
                      newInputFields[0].error = true
                      newInputFields[0].message = "Length must be equal to 12."
                    }
                    else if (metadata.data?.some((item) => (item?.MetadataValue === data.value)
                      && (item?.GroupKey?.toLowerCase() === vendorNameFromOnChange?.toLowerCase()))) {
                      newInputFields[0].error = true
                      newInputFields[0].message = "Vendor Account Already Exists!"
                    }
                    else if (data?.value?.length === 12) {
                      if (whiteListTrustedVendor?.data?.some((item) => parseInt(item) === parseInt(data?.value))) {
                        newInputFields[0].error = false
                      }
                      else if (blackListTrustedVendor?.data?.some((item) => parseInt(item) === parseInt(data?.value))) {
                        newInputFields[0].error = true
                        newInputFields[0].message = "Vendor account is not approved!"
                      }
                      else {
                        setAccountDetail({ data: [], isLoading: true })
                        //To check account is blacklisted or not.
                        let url = commercialCloud === props?.selectCloud?.toLowerCase() ? config.apiendpoint : config.govCloudApi
                        axios.get(`${url}query?Table=${'ACCOUNTS_TABLE'}&Key=${'AccountId'}&Value=${data?.value}`,
                          {
                            headers: {
                              'Authorization': token
                            }
                          }).then((res => {

                            if (res.status === 200) {
                              newInputFields[0].error = true
                              newInputFields[0].message = "Please enter a valid trusted vendor account!"
                              setAccountDetail({ data: res.data, isLoading: false })
                            }
                            else {
                              setAccountDetail({ data: [], isLoading: false })
                            }

                          })).catch(error => {
                            setAccountDetail({ data: [], isLoading: false })
                          })
                      }
                    }
                    setVendorIds(newInputFields)
                  }
                }
                else {
                  let patternMatcher = /^[^\/]+\/[^\/]+$/;
                  const newInputFields = [...vendorIds]
                  newInputFields[0].value = data?.value
                  newInputFields[0].error = false
                  newInputFields[0].message = ""

                  if (metadata.data?.some((item) => (item?.MetadataValue === data.value)
                    && (item?.GroupKey?.toLowerCase() === value.vendorName?.toLowerCase()))) {
                    newInputFields[0].error = true
                    newInputFields[0].message = "AMI source pattern already exists!"
                  }
                  else if (vendorIds?.filter((item) => (item?.value?.toLowerCase() === data.value?.toLowerCase()))?.length > 1) {
                    newInputFields[0].error = true
                    newInputFields[0].message = "AMI source pattern already entered!"
                  }
                  else if (!patternMatcher.test(data?.value)) {
                    newInputFields[0].error = true
                    newInputFields[0].message = "AMI source pattern is not valid!"

                  }
                  setVendorIds(newInputFields)

                }

              }}
              style={{ padding: 0, width: "80%" }}
              placeholder="e.g., 601306020600 or (601306020600/databricks-worker or amazon/amzn-ec2)"
              error={vendorIds[0].error}
            />
            {vendorIds[0].error ? (<div className="ui basic"
              style={{ color: "#db2828", marginBottom: "7px", marginTop: "7px", fontSize: "10px" }}>
              {vendorIds[0].message}</div>) :
              null}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button info onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button style={{ backgroundColor: "rgb(42, 125, 225)", color: "#fff" }}
            onClick={handleUpdateTrustedVendor}
            disabled={(vendorIds.some(item => item.error) || vendorIds.some((item) => item.value === ""))}>
            Update
          </Button>
        </Modal.Actions>
      </Modal>
      <fieldset style={{ marginTop: 40, border: "1px solid #cfcfcf" }}>
        <legend style={{ padding: "5px 10px", backgroundColor: "rgb(42, 125, 225)", color: "#fff" }}>Update Trusted Vendor</legend>
        <div style={{ marginTop: "30px", padding: 10 }}>
          <Grid columns={3} stackable>
            <Grid.Row>
              <Grid.Column>
                <div><label style={{ fontWeight: 700, fontSize: "12px" }}>Select Trusted Vendor<span style={{ color: '#db2828' }}>*</span></label></div>
                <div style={{display:"flex"}}>
                <Select
                  search
                  loading={metadata?.isLoading}
                  placeholder='Select'
                  options={metadata.listOfTrustedVendor}
                  style={{ minWidth: "90%" }}
                  onChange={(e, data) => {
                    setVendorNameFromOnChange(data.value)
                  }}
                  value={vendorNameFromOnChange}
                />
                {vendorNameFromOnChange !== "" ? (<div style={{ marginLeft:50 }}>
                  <Button onClick={() => handleOpenModal()} style={{ backgroundColor: "rgb(42, 125, 225)", color: "#fff", fontSize: 13, width:"180px" }}
                  >
                    Update Vendor
                  </Button>
                </div>) : null}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <div style={{ marginTop: "30px", width:400 }}>
            {metadata.data?.filter((item) => item.GroupKey.toLowerCase() === vendorNameFromOnChange.toLowerCase())?.length > 0 ? (
              <Table celled padded>
                <Table.Header>
                  <Table.Row>
                    {["Vendor Account ID / AMI Source Pattern"].map(
                      (item, index) => (<Table.HeaderCell textAlign="center" key={index} >{item}</Table.HeaderCell>))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {!metadata?.isLoading ? (
                    metadata.data?.filter((item) => item.GroupKey.toLowerCase() ===
                    vendorNameFromOnChange.toLowerCase())?.map((seletedItem, index) => (
                      <Table.Row key={index}>
                        <Table.Cell textAlign="center">
                          <b>{seletedItem?.MetadataValue}</b>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : <>Loading...</>}
                </Table.Body>
              </Table>
            ) : null}
          </div>
        </div>
      </fieldset>
    </React.Fragment>
  )
}